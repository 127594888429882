<template>
    <div :class="classes">
        <div class="w-full">
            <div v-if="$slots['title']" :class="`text-${colors[color]}-800`">
                <div>
                    <slot name="title" :class="`text-${colors[color]}-800`" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: false,
            default: () => "info",
        },
    },
    data() {
        return {
            baseClasses: "",
            colors: {
                info: "primary",
                success: "green",
                warning: "yellow",
                danger: "red",
            },
        };
    },
    computed: {
        classes() {
            return `alert ${this.baseClasses} bg-gray-800`;
        },
    },
};
</script>
